// @ts-check
/* eslint-disable valid-jsdoc */
import { configKey, getCookie } from './cookies'
import { languages, serverCountryCodeMap, countryConfig, defaultCountryKey, defaultCountryId, defaultCountry, defaultLanguageFullName, defaultLanguage, defaultLanguageId, defaultCurrency, nations, defaultUrl } from './country.base'

export const internationalCountry = countryConfig.find((item) => item.key === 'com')
export const getLanguageCode = (langKey) => serverCountryCodeMap[langKey] ?? langKey
export const getLangKeyByCode = (langCode) => {
  const langKey = Object.keys(serverCountryCodeMap).find(
    (key) => serverCountryCodeMap[key] === langCode
  )
  return langKey ?? langCode
}

export const getServerLanguageCode = (langCode) =>
  serverCountryCodeMap[langCode] || serverCountryCodeMap['en-US']


export const getDefaultLocation = (key) => {
  return countryConfig.find((item) => item.key === key)?.defaultLocation
}


export const getSEOLang = (countryKey) => {
  const cKey = countryKey || getCountryKey()
  const country = countryConfig.find((item) => item.key === cKey)
  return country?.seoLang
}

export const getCountryKey = (defaultPath = undefined) => {
  const path =
    typeof window === 'undefined'
      ? defaultPath
      : window.location.href

  let country = countryConfig.find((item) => path?.includes?.(item.url))
  // 兼容处理demo.bossjob.com.tr 和 demo.bossjob.com
  if (path?.indexOf('.tr') > -1) {
    country = countryConfig.find((item) => item.key === 'tr')
  }
  if (path?.indexOf('.br') > -1) {
    country = countryConfig.find((item) => item.key === 'br')
  }

  if (country) {
    return country.key
  } else {
    return defaultCountryKey()
  }
}

/**
 * get countryId by URL and supported countries
 * @returns
 */
export const getCountryId = (key) => {
  const countryKey = key ?? getCountryKey()
  return countryConfig.find((item) => item.key === countryKey)?.id ?? defaultCountryId()
}

export const getUrl = (host) => {
  const countryKey = getCountryKey(host)
  const originUrl = countryConfig.find((item) => item.key === countryKey)?.url ?? defaultUrl()
  const prefix = process.env.NEXT_PUBLIC_URL_PREFIX || '';

  return `https://${prefix}${originUrl}`
}

export const getUrlByCountryKey = (countryKey) => {
  const originUrl = countryConfig.find((item) => item.key === countryKey)?.url ?? defaultUrl()
  const prefix = process.env.NEXT_PUBLIC_URL_PREFIX || '';

  return `https://${prefix}${originUrl}`
}

/**
 * get language code by URL or geoConfiguration in cookies
 * @returns
 */
export const getLang = () => {
  let path = typeof window === 'undefined' ? '' : window.location.href
  path = path?.split?.('//')[1]?.split?.('/')?.[1] // https://dev.bossjob.sg/en-US/...
  return languages.map((item) => item.value).includes(path)
    ? path
    : getCookie(configKey)?.split('_')?.[1] || defaultLanguage()
}

/**
 * get language's full name from url or geoConfiguration in cookies
 * @returns
 */
export const getLanguage = () => {
  const langCode = getLang()
  const currentLang = languages.find((item) => item.value === langCode)

  return currentLang?.label || defaultLanguageFullName()
}

/**
 * get current language
 * @returns
 */
export const getCurLanguage = (languageOptions = languages) => {
  const langCode = getLang()
  const currentLang = languageOptions.find((item) => item.value === langCode)
  const currentLangId = currentLang?.id || languageOptions[0].id
  const currentLangFullName = currentLang?.label || languageOptions[0].label
  const currentLangSlug = currentLang?.slug || languageOptions[0].slug
  const currentLangAlias = currentLang?.alias || languageOptions[0].alias


  return {
    currentLangCode: langCode,
    currentLangId,
    currentLangFullName,
    currentLangSlug,
    currentLangAlias
  }
}

/**
 * get language's id from url or geoConfiguration in cookies
 * @returns
 * code 参数用于处理直接修改路由，导致修复首次获取到的值还说上一次的值
 */
export const getLanguageId = (code) => {
  const langCode = code || getLang()
  const currentLang = languages.find((item) => item.value === langCode)
  return currentLang?.id || defaultLanguageId()
}
/**
 * get country's full name from url, e.g. ph => Philippines
 * @returns
 */
export const getCountry = () => {
  const countryKey = getCountryKey()
  const country = nations.find((v) => v.value === countryKey)

  return country?.label || defaultCountry()
}

export const countryForCurrency = (key) => {
  return countryConfig.find((item) => item.key === key)?.currency ?? defaultCurrency()
}

export const countryForPhoneCode = {
  ph: '+63',
  sg: '+65',
  jp: '+81',
  id: '+62',
  my: '+60',
  us: '+1',
  ca: '+1',
  hk: '+852',
  tw: '+886',
  gb: '+44',
  tr: '+90',
  br: '+55',
  ae: '+971',
  ng: '+234',
  lk: '+94'
}

export const getAppStoreLink = () => {
  let key = getCountryKey()
  if (key == 'com') {
    key = 'sg'
  }
  return process.env.APP_STORE_LINK.replace('COUNTRY_KEY', key)
}

/**
 * cloudflare ip country, return country object
 * @param {string} countryKey - e.g. [com, ph]
 * @returns {object} country
 */
export const cfCountrySupported = (countryKey) => {
  const country = countryConfig.find((v) => v.key === countryKey)
  const isSupportCountry = !!country
  return isSupportCountry ? country : internationalCountry
}
