// h1 map for country
export const h1Map = {
  sg: 'Find Job. Talk to Boss',
  ph: 'Find Job. Talk to Boss',
  com: 'Find Job. Talk to Boss',
  id: 'Mencari pekerjaan. Bicaralah dengan Bos',
  // jp: '仕事を探す。ボスと話す。',
  jp: 'Find Job. Talk to Boss',
  my: 'Cari Pekerjaan. Berbincang dengan Bos',
  hk: '找工作，與老闆談',
  tw: '找工作，與老闆談',
  tr: 'İş bul. Patronla konuş',
  ca: 'Find Job. Talk to Boss',
  gb: 'Find Job. Talk to Boss',
  us: 'Find Job. Talk to Boss',
  ng: 'Find Job. Talk to Boss',
  ae: 'Find Job. Talk to Boss',
  lk: 'Find Job. Talk to Boss',
  es: 'Encuentra trabajo. Habla con tu jefe',
  rs: 'Find Job. Talk to Boss',
  br: 'Encontre emprego, fale com o chefe',
}
// seo titles for country
export const titleMap = {
  sg: 'Bossjob Singapore - Find Job. Talk to Boss',
  ph: 'Bossjob Philippines - Find Job. Talk to Boss',
  com: 'Bossjob Global & Remote - Find Job. Talk to Boss',
  id: 'Bossjob Indonesia - cari pekerjaan ngobrol dengan bos',
  jp: 'Bossjob Japan - Find Job. Talk to Boss',
  // jp: 'Bossjob 日本 - 仕事を探す。ボスと話す。',
  my: 'Bossjob Malaysia - cari kerja berbual dengan majikan',
  hk: 'Bossjob 香港 - 找工作，與老闆談',
  tw: 'Bossjob 台灣 - 找工作，與老闆談',
  tr: 'Bossjob Turkiye - Find Job. Talk to Boss',
  ca: 'Bossjob Canada - Find Job. Talk to Boss',
  gb: 'Bossjob U.K. - Find Job. Talk to Boss',
  us: 'Bossjob USA - Find Job. Talk to Boss',
  ng: 'Bossjob Nigeria - Find Job. Talk to Boss',
  ae: 'Bossjob United Arab Emirates - Find Job. Talk to Boss',
  lk: 'Bossjob Sri Lanka - Find Job. Talk to Boss',
  es: 'Bossjob Spain - Encuentra trabajo. Habla con tu jefe',
  rs: 'Bossjob Serbia - Find Job. Talk to Boss',
  br: 'Bossjob Brazil - Encontre emprego, fale com o chefe'
}

export const gtagIdMap = {
  com: 'G-9KN6R22NRD',
  ph: 'G-N931FTT7S6',
  sg: 'G-MWM11VMJLV',
  jp: 'G-VGMF8G3GF0',
  id: 'G-N98RKC72T2',
  hk: 'G-C6H4754RZ1',
  tw: 'G-E1FNL6LESC',
  my: 'G-3BWY2Z8PHG',
  tr: 'G-E5RLYMDG49',
  us: '',
  gb: '',
  ca: '',
  ng: '',
  ae: '',
  br: '',
  lk: '',
  es: '',
  rs: ''
}
// Warning: the English language's value must be en-us,
export const countryConfig = [
  {
    name: 'Japan',
    key: 'jp',
    url: 'bossjob.jp',
    seoLang: 'ja',
    defaultLocation: {
      id: 1714,
      is_popular: false,
      key: 'chuo_ku',
      region_display_name: 'Tokyo To',
      seo_value: 'chuo-ku',
      value: 'Chuo Ku'
    },
    currency: 'jpy',
    id: 105,
    hrefLang: 'ja-JP'
  },
  {
    name: 'Philippines',
    key: 'ph',
    url: 'bossjob.ph',
    seoLang: 'en-us',
    defaultLocation: {
      id: 63,
      key: 'manila',
      value: 'Manila',
      is_popular: false,
      region_display_name: 'National Capital Region',
      seo_value: 'manila'
    },
    currency: 'php',
    id: 167,
    hrefLang: 'en-PH'
  },
  {
    name: 'Singapore',
    key: 'sg',
    url: 'bossjob.sg',
    seoLang: 'en-us',
    defaultLocation: {
      id: 165,
      is_popular: false,
      key: 'downtown_core',
      region_display_name: 'Central',
      seo_value: 'downtown-core',
      value: 'Downtown Core'
    },
    currency: 'sgd',
    id: 193,
    hrefLang: 'en-SG'
  },
  {
    name: 'Malaysia',
    key: 'my',
    url: 'bossjob.my',
    seoLang: 'ms',
    defaultLocation: {
      id: 2918,
      is_popular: false,
      key: 'kuala_lumpur',
      region_display_name: 'Kuala Lumpur',
      seo_value: 'kuala_lumpur',
      value: 'Kuala Lumpur'
    },
    currency: 'myr',
    id: 127,
    hrefLang: 'ms-MY'
  },

  {
    name: 'Indonesia',
    key: 'id',
    url: 'bossjob.id',
    seoLang: 'id',
    defaultLocation: {
      id: 450,
      is_popular: false,
      key: 'jakarta_pusat',
      region_display_name: 'Jakarta Pusat',
      seo_value: 'jakarta_pusat',
      value: 'Jakarta Pusat'
    },
    currency: 'idr',
    id: 96,
    hrefLang: 'id-ID'
  },
  {
    name: 'Hong Kong',
    key: 'hk',
    url: 'bossjob.hk',
    seoLang: 'zh-tw',
    defaultLocation: {
      id: 2857,
      is_popular: false,
      key: 'cheung_chau_area',
      region_display_name: 'Cheung Chau Area',
      seo_value: 'cheung_chau_area',
      value: 'Cheung Chau Area'
    },
    currency: 'hkd',
    id: 92,
    hrefLang: 'zh-HK'
  },
  {
    name: 'Taiwan',
    key: 'tw',
    url: 'bossjob.tw',
    seoLang: 'zh-tw',
    defaultLocation: {
      id: 2487,
      is_popular: false,
      key: 'zhongzheng_district',
      region_display_name: 'Zhongzheng District',
      seo_value: 'zhongzheng_district',
      value: 'Zhongzheng District'
    },
    currency: 'twd',
    id: 211,
    hrefLang: 'zh-TW'
  },
  {
    name: 'United States',
    shortName: 'USA',
    key: 'us',
    url: 'bossjob.us',
    seoLang: 'en-us',
    defaultLocation: {
      id: 4494,
      key: 'washington',
      value: 'Washington',
      is_popular: false,
      region_display_name: 'Washington',
      seo_value: 'washington'
    },
    currency: 'usd',
    id: 229,
    hrefLang: 'en-US'
  },
  {
    name: 'United Kingdom',
    shortName: 'UK',
    key: 'gb',
    url: 'bossjob.uk',
    seoLang: 'en-us',
    defaultLocation: {
      id: 4331,
      key: 'london',
      value: 'London',
      is_popular: false,
      region_display_name: 'England',
      seo_value: 'england'
    },
    currency: 'gbp',
    id: 228,
    hrefLang: 'en-GB'
  },
  {
    name: 'Canada',
    key: 'ca',
    url: 'bossjob.ca',
    seoLang: 'en-us',
    defaultLocation: {
      id: 4498,
      key: 'ontario',
      value: 'Ontario',
      is_popular: false,
      region_display_name: 'National Capital Region',
      seo_value: 'ontario'
    },
    currency: 'cad',
    id: 38,
    hrefLang: 'en-CA'
  },

  {
    name: 'Brazil',
    key: 'br',
    url: 'bossjob.com.br',
    seoLang: 'pt-br',
    defaultLocation: {
      id: 4521,
      key: 'distrito_federal',
      value: 'Distrito Federal',
      is_popular: false,
      region_display_name: 'Distrito Federal',
      seo_value: 'distrito-federal'
    },
    currency: 'brl',
    id: 29,
    hrefLang: 'pt-BR'
  },
  {
    name: 'Türkiye',
    key: 'tr',
    url: 'bossjob.com.tr',
    seoLang: 'tr',
    defaultLocation: {
      id: 3260,
      is_popular: false,
      key: 'akyurt',
      region_display_name: 'Ankara',
      seo_value: 'akyurt',
      value: 'Akyurt'
    },
    currency: 'try',
    id: 220,
    hrefLang: 'tr-TR'
  },

  {
    name: 'Nigeria',
    key: 'ng',
    url: 'bossjob.ng',
    seoLang: 'en-us',
    defaultLocation: {
      id: 4574,
      key: 'federal_capital_territory',
      value: 'Federal Capital Territory',
      is_popular: false,
      region_display_name: 'Federal Capital Territory',
      seo_value: 'ederal-capital-territory'
    },
    currency: 'ngn',
    id: 154,
    hrefLang: 'en-NG'
  },
  {
    name: 'United Arab Emirates',
    shortName: 'UAE',
    key: 'ae',
    url: 'bossjob.ae',
    seoLang: 'en-us',
    defaultLocation: {
      id: 4575,
      key: 'emirate_of_abu_dhabi',
      value: 'Emirate of Abu Dhabi',
      is_popular: false,
      region_display_name: 'Emirate of Abu Dhabi',
      seo_value: 'emirate-of-abu-dhabi'
    },
    currency: 'aed',
    id: 227,
    hrefLang: 'en-AE'
  },
  {
    name: 'Sri Lanka',
    key: 'lk',
    url: 'bossjob.lk',
    seoLang: 'en-us',
    defaultLocation: {
      id: 4590,
      key: 'western_province',
      value: 'Western Province',
      is_popular: false,
      region_display_name: 'Western Province',
      seo_value: 'western-province'
    },
    currency: 'lkr',
    id: 203,
    hrefLang: 'en-LK'
  },
  {
    name: 'Spain',
    key: 'es',
    url: 'bossjob.es',
    seoLang: 'es-es',
    defaultLocation: {
      id: 4647,
      key: 'madrid',
      value: 'Madrid',
      is_popular: false,
      region_display_name: 'Madrid',
      seo_value: 'madrid'
    },
    currency: 'eur',
    id: 202,
    hrefLang: 'es-ES'
  },
  {
    name: 'Serbia',
    key: 'rs',
    url: 'bossjob.rs',
    seoLang: 'en-us',
    defaultLocation: {
      id: 4653,
      key: 'beograd',
      value: 'Beograd',
      is_popular: false,
      region_display_name: 'Beograd',
      seo_value: 'beograd'
    },
    currency: 'rsd',
    id: 190,
    hrefLang: 'en-RS'
  },
  {
    name: 'Others',
    key: 'com',
    url: 'bossjob.com',
    seoLang: 'en-us',
    defaultLocation: {
      id: 165,
      is_popular: false,
      key: 'downtown_core',
      region_display_name: 'Central',
      seo_value: 'downtown-core',
      value: 'Downtown Core'
    },
    currency: 'usd',
    id: 241,
    hrefLang: 'x-default'
  },

]



export const languages = [
  { value: 'en-us', id: 1, label: 'English', slug: 'EN', alias: 'en' },
  { value: 'zh-cn', id: 2, label: '中文 (简体)', slug: 'zh-CN', alias: 'zh' },
  { value: 'zh-tw', id: 5, label: '中文(繁體)', slug: 'zh-TW', alias: 'zh-hk' },
  { value: 'ja', id: 4, label: '日本語', slug: 'JA', alias: 'ja' },
  { value: 'es-es', id: 9, label: 'Español', slug: 'es-ES', alias: 'es' },
  { value: 'pt-br', id: 8, label: 'Português', slug: 'pt-BR', alias: 'pt-br' },
  { value: 'ms', id: 6, label: 'Bahasa Malaysia', slug: 'MY', alias: 'ms' },
  { value: 'id', id: 3, label: 'Bahasa Indonesia', slug: 'ID', alias: 'id' },
  { value: 'tr', id: 7, label: 'Türkçe', slug: 'TR', alias: 'tr' },
]

export const languages_h5 = [
  { value: 'en-us', id: 1, label: 'English', slug: 'EN', alias: 'en' },
  { value: 'zh-cn', id: 2, label: '中文 (简体)', slug: 'CN', alias: 'zh' }
]

export const serverCountryCodeMap = {
  'en-US': 'en',
  'en-us': 'en',
  'zh-CN': 'zh-CN',
  'zh-cn': 'zh-CN',
  'id-ID': 'id',
  id: 'id',
  'ja-JP': 'ja',
  ja: 'ja',
  'zh-TW': 'zh-TW',
  'zh-tw': 'zh-TW',
  'ms-MY': 'ms',
  ms: 'ms',
  tr: 'tr',
  'pt-br': 'pt-BR',
  'es-es': 'es-ES',
  'es-ES': 'es-ES'
}
export const convertClientToBackendLang = (lang) => {
  return serverCountryCodeMap[lang] || lang
}

// 兼容以前的语言，建立与新语言的映射。新的方法不要使用
export const oldIntoNewLanguageMap = {
  'en-US': 'en-us',
  'zh-CN': 'zh-cn',
  'id-ID': 'id',
  'ja-JP': 'ja',
  'zh-TW': 'zh-tw',
  'ms-MY': 'ms',
}
export const nations = countryConfig.map((item) => {
  return {
    value: item.key,
    label: item.name,
    id: item.id
  }
})


const defaultNation = nations.find((item) => item.value == 'ph')

export const defaultCountryKey = () => defaultNation.value
export const defaultCountryId = () => defaultNation.id
export const defaultCountry = () => defaultNation.label
export const defaultCurrency = () => countryConfig[0].currency
export const defaultUrl = () => countryConfig[0].url
export const defaultLanguage = () => languages[0].value
export const defaultLanguageFullName = () => languages[0].label
export const defaultLanguageId = () => languages[0].id
