"use client";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import Error from "app/components/error";
export default function PageError(props: { error: any; reset?: () => void }) {
  const { error } = props;
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <Error {...props} />;
}
